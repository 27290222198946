var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tableData)?_c('fragment',[_c('section',{staticClass:"control"},[_c('div',{staticClass:"con_ti"}),_c('div',{staticClass:"btn"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":this.addDict}},[_vm._v("新增")])],1)]),(_vm.tableData)?_c('section',{staticClass:"tabulation"},[_c('Tabulation',{attrs:{"data":{
        columnName: [
          {name: '字典编码', prop: 'dictionaryCode', type: 'text', needSort: false},
          {name: '字典名称', prop: 'dictionaryName', type: 'text', needSort: false, style: {flex: 2}},
          {name: '状态', prop: 'status', type: 'switch', needSort: false},
          {name: '字典值', prop: 'dictionaryValue', type: 'text', needSort: false},
        ],
        rows: this.tableData,
        control: [
          {name: '修改', type: 'warning', method: this.updateDict},
          {name: '删除', type: 'danger', method: this.delDict},
        ]
      }},on:{"updateStatus":_vm.updateTabStatus}})],1):_vm._e(),_c('section',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","current-page":this.pageNum,"page-sizes":[10, 20, 50, 100],"page-size":this.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":this.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)]):_c('fragment',[_c('img',{staticClass:"loading",attrs:{"src":require("../../../assets/images/loading.gif"),"height":"100","width":"100","alt":"加载中"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }